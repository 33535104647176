import { cleanStack } from './utils.js';

interface ExceptionType {
    code: string;
    statusCode: number;
    message: string;
    level: 'error' | 'warn';
}

interface ExceptionTypeDef {
    [k: string]: ExceptionType;
}

export const GeneralErrors = {
    INVALID_REQUEST: {
        code: 'G001',
        statusCode: 400,
        message: 'Invalid request.',
        level: 'warn',
    },

    NO_PERMISSIONS: {
        code: 'G003',
        statusCode: 403,
        message: 'User does not have permissions.',
        level: 'warn',
    },

    NOT_FOUND: {
        code: 'G004',
        statusCode: 404,
        message: 'Not found.',
        level: 'warn',
    },

    BACKUP_FAILED: {
        code: 'G005',
        statusCode: 500,
        message: 'Backup failed.',
        level: 'error',
    },

    LOADING_ERROR: {
        code: 'G006',
        statusCode: 500,
        message: 'Loading error.',
        level: 'warn',
    },

    INVALID_REFERRER: {
        code: 'G007',
        statusCode: 400,
        message: 'Invalid referrer.',
        level: 'warn',
    },

    UNKNOWN_ERROR: {
        code: 'G999',
        statusCode: 500,
        message: 'Unknown error occured.',
        level: 'error',
    },
} satisfies ExceptionTypeDef;

export const TeamErrors = {
    TEAM_FULL: {
        code: 'T002',
        statusCode: 400,
        message: 'Team is full.',
        level: 'warn',
    },

    INVALID_LINK: {
        code: 'T003',
        statusCode: 400,
        message: 'Invalid join link.',
        level: 'warn',
    },

    ALREADY_JOINED: {
        code: 'T004',
        statusCode: 400,
        message: 'User already joined to team.',
        level: 'warn',
    },

    MEMBER_OF_PAID_TEAM: {
        code: 'T005',
        statusCode: 400,
        message: 'User is a member of a paid team.',
        level: 'warn',
    },
} satisfies ExceptionTypeDef;

export const BillingErrors = {
    CARD_DECLINED: {
        code: 'B001',
        statusCode: 400,
        message: 'Card was declined.',
        level: 'warn',
    },

    CARD_EXPIRED: {
        code: 'B002',
        statusCode: 400,
        message: 'Card is expired.',
        level: 'warn',
    },

    INCORRECT_CVC: {
        code: 'B003',
        statusCode: 400,
        message: 'CVC is incorrect.',
        level: 'warn',
    },

    PROCESSING_ERROR: {
        code: 'B004',
        statusCode: 500,
        message: 'Processing error.',
        level: 'error',
    },

    INVALID_COUPON: {
        code: 'B005',
        statusCode: 400,
        message: 'Coupon is not valid.',
        level: 'warn',
    },

    NO_BILLING_ENTITY: {
        code: 'B006',
        statusCode: 500,
        message: 'Team owner with no billing entity.',
        level: 'error',
    },

    MISMATCHED_SUBSCRIPTION: {
        code: 'B007',
        statusCode: 500,
        message: 'Subscription from entity did not match webhook.',
        level: 'error',
    },

    NO_PRICE_FOR_PLAN: {
        code: 'B008',
        statusCode: 500,
        message: 'No price found for entity plan.',
        level: 'error',
    },

    NO_INVOICE_ITEM_FOR_PRICE: {
        code: 'B009',
        statusCode: 500,
        message: 'No invoice item for the expected price.',
        level: 'error',
    },

    RETRY_INVOICE_FAILED: {
        code: 'B010',
        statusCode: 500,
        message: 'Retry invoice failed.',
        level: 'error',
    },

    HAS_TEAM_SUBSCRIPTION: {
        code: 'B011',
        statusCode: 400,
        message: 'User has an active team subscription.',
        level: 'warn',
    },

    NO_PAYMENT_METHOD: {
        code: 'B012',
        statusCode: 400,
        message: 'No payment method available.',
        level: 'error',
    },

    INCORRECT_NUMBER: {
        code: 'B013',
        statusCode: 400,
        message: 'Card number is incorrect.',
        level: 'warn',
    },
} satisfies ExceptionTypeDef;

export const NotificationErrors = {
    UNKNOWN_NOTIFICATION: {
        code: 'N001',
        statusCode: 500,
        message: 'Unknown notification type.',
        level: 'error',
    },

    SENDER_ID_MISMATCH: {
        code: 'N002',
        statusCode: 400,
        message: 'Sender ID mismatch.',
        level: 'warn',
    },

    NO_BASIC_DETAILS: {
        code: 'N003',
        statusCode: 500,
        message: 'No basic details for user.',
        level: 'error',
    },

    UNKNOWN_TOPIC: {
        code: 'N004',
        statusCode: 500,
        message: 'Unknown pub/sub topic',
        level: 'error',
    },
} satisfies ExceptionTypeDef;

export const AuthenticationErrors = {
    UNKNOWN_USER: {
        code: 'A001',
        statusCode: 404,
        message: 'An account with this email was not found.',
        level: 'warn',
    },

    INVALID_EMAIL: {
        code: 'A002',
        statusCode: 400,
        message: 'Invalid email.',
        level: 'warn',
    },

    INVALID_PASSWORD: {
        code: 'A003',
        statusCode: 400,
        message: 'Invalid password.',
        level: 'warn',
    },

    EMAIL_ALREADY_EXISTS: {
        code: 'A004',
        statusCode: 400,
        message: 'An account with this email already exists.',
        level: 'warn',
    },

    WEAK_PASSWORD: {
        code: 'A005',
        statusCode: 400,
        message: 'Password is too weak.',
        level: 'warn',
    },

    INCORRECT_PASSWORD: {
        code: 'A006',
        statusCode: 400,
        message: 'Password is incorrect.',
        level: 'warn',
    },

    USER_DISABLED: {
        code: 'A007',
        statusCode: 400,
        message: 'User account is disabled.',
        level: 'warn',
    },

    TOO_MANY_ATTEMPTS: {
        code: 'A008',
        statusCode: 400,
        message: 'Too many login attempts.',
        level: 'warn',
    },

    REQUIRES_RECENT_LOGIN: {
        code: 'A009',
        statusCode: 400,
        message: 'Operation requires recent login.',
        level: 'warn',
    },

    INVALID_ACTION_CODE: {
        code: 'A010',
        statusCode: 400,
        message: 'Invalid action code.',
        level: 'warn',
    },
} satisfies ExceptionTypeDef;

export const SharingErrors = {
    PLAY_ALREADY_SHARED: {
        code: 'S001',
        statusCode: 400,
        message: 'Play is already shared.',
        level: 'warn',
    },
} satisfies ExceptionTypeDef;

export const PlayEditorErrors = {
    UNKNOWN_ITEM_TYPE: {
        code: 'P001',
        statusCode: 400,
        message: 'Unknown item type.',
        level: 'error',
    },

    INVALID_PLAYER_UPDATE: {
        code: 'P002',
        statusCode: 400,
        message: 'Player should not be updated in this way after the first frame.',
        level: 'error',
    },

    INVALID_COMMENT_ID: {
        code: 'P003',
        statusCode: 400,
        message: 'Invalid comment ID.',
        level: 'error',
    },

    NAN_VALUE: {
        code: 'P004',
        statusCode: 400,
        message: 'Invalid position found for play component.',
        level: 'error',
    },

    INVALID_FIELD_CONFIG: {
        code: 'P005',
        statusCode: 400,
        message: 'No valid field config.',
        level: 'error',
    },
} satisfies ExceptionTypeDef;

export const ExceptionCodes = [
    GeneralErrors,
    TeamErrors,
    BillingErrors,
    NotificationErrors,
    AuthenticationErrors,
    PlayEditorErrors,
].flatMap((errorType) => Object.values(errorType).map(({ code }) => code));

export function isKnownException(code: string) {
    return ExceptionCodes.includes(code);
}

export class Exception extends Error {
    code: string;
    statusCode: number;
    details?: string;
    level: 'error' | 'warn';
    nestedError?: Error & { code?: any };

    constructor(
        message: string,
        code: string,
        statusCode: number,
        level: 'error' | 'warn',
        details?: string,
        nestedError?: Error
    ) {
        super(message);

        this.code = code;
        this.statusCode = statusCode;
        this.details = details;
        this.level = level;
        this.nestedError = nestedError;
    }

    is(exceptionType: ExceptionType): boolean {
        return this.code === exceptionType.code;
    }

    isOfType(exceptionTypeDef: ExceptionTypeDef): boolean {
        return Object.values(exceptionTypeDef).some((exceptionType) => this.code === exceptionType.code);
    }

    response() {
        return {
            code: this.code,
            details: this.details ?? this.nestedError?.message,
            level: this.level,
            message: this.message,
        };
    }

    fullMessage() {
        if (this.details && typeof this.details === 'string') {
            return `${this.message} ${this.details}`;
        }

        return this.message;
    }

    toJSON(baseUrl = '') {
        const nestedErrorDetails = {
            message: this.nestedError?.message,
            name: this.nestedError?.name,
            stack: cleanStack(this.nestedError?.stack, baseUrl),
        };

        const exceptionDetails = {
            message: this.message,
            name: this.name,
            stack: cleanStack(this.stack, baseUrl),
        };

        const topLevelDetails = this.nestedError ? nestedErrorDetails : exceptionDetails;

        return {
            code: this.code,
            statusCode: this.statusCode,
            details: this.details,
            level: this.level,
            ...topLevelDetails,
            exception: this.nestedError ? exceptionDetails : null,
        };
    }
}

export function createException(
    errorType: ExceptionType,
    { details, nestedError }: { details?: string; nestedError?: Error } = {}
): Exception {
    if (errorType.code === GeneralErrors.UNKNOWN_ERROR.code && !nestedError?.message && !details) {
        return createException(errorType, { nestedError: new Error('Unknown error without nestedError or details') });
    }

    return new Exception(
        errorType.message,
        errorType.code,
        errorType.statusCode,
        errorType.level,
        details,
        (nestedError instanceof Exception ? nestedError.nestedError : undefined) ?? nestedError
    );
}

export function createClientException(clientException: {
    message: string;
    stack: string;
    code: string;
    statusCode: number;
    level: 'error' | 'warn';
    details?: string;
    nestedError?: Error;
}): Exception {
    const { message, code, statusCode, level, details, nestedError, stack } = clientException;
    const exc = new Exception(message, code, statusCode, level, details, nestedError);

    exc.stack = stack;

    return exc;
}

export function getAuthError(error: any) {
    const exceptionType = Object.values(AuthenticationErrors).find((et) => error.code === et.code);
    if (exceptionType) {
        return createException(exceptionType);
    }

    if (error.message === 'There is no user record corresponding to the provided email.') {
        return createException(AuthenticationErrors.UNKNOWN_USER);
    }

    const map = {
        'auth/invalid-email': AuthenticationErrors.INVALID_EMAIL,
        'auth/user-not-found': AuthenticationErrors.UNKNOWN_USER,
        'auth/invalid-password': AuthenticationErrors.INVALID_PASSWORD,
        'auth/email-already-exists': AuthenticationErrors.EMAIL_ALREADY_EXISTS,
        'auth/email-already-in-use': AuthenticationErrors.EMAIL_ALREADY_EXISTS,
        'auth/weak-password': AuthenticationErrors.WEAK_PASSWORD,
        'auth/requires-recent-login': AuthenticationErrors.REQUIRES_RECENT_LOGIN,
        'auth/user-disabled': AuthenticationErrors.USER_DISABLED,
        'auth/too-many-requests': AuthenticationErrors.TOO_MANY_ATTEMPTS,
        'auth/wrong-password': AuthenticationErrors.INCORRECT_PASSWORD,
        'auth/network-request-failed': GeneralErrors.LOADING_ERROR,
        'auth/invalid-action-code': AuthenticationErrors.INVALID_ACTION_CODE,
        'auth/expired-action-code': AuthenticationErrors.INVALID_ACTION_CODE,
        'auth/missing-password': AuthenticationErrors.INVALID_PASSWORD,
        'auth/user-token-expired': AuthenticationErrors.REQUIRES_RECENT_LOGIN,
    };

    return map[error.code]
        ? createException(map[error.code])
        : createException(GeneralErrors.UNKNOWN_ERROR, { nestedError: error });
}

// Interprets common general errors or authentication errors
export function getCommonError(error: any) {
    if (error instanceof Exception) {
        return error;
    }

    if (isKnownException(error.code)) {
        return createException(error, { details: error.details, nestedError: error.nestedError });
    }

    if (
        [
            'Failed to fetch',
            'Load failed',
            'Failed to get document because the client is offline.',
            'Firebase Storage: Max retry time for operation exceeded, please try again. (storage/retry-limit-exceeded)',
            'Connection to Indexed Database server lost. Refresh the page to try again',
            'Firebase: Error (auth/network-request-failed).',
            'NetworkError when attempting to fetch resource.',
        ].includes(error.message)
    ) {
        return createException(GeneralErrors.LOADING_ERROR, { nestedError: error, details: error.message });
    }

    if (error.message === 'Missing or insufficient permissions.') {
        return createException(GeneralErrors.NO_PERMISSIONS, { nestedError: error });
    }

    // Will handle UNKNOWN_ERROR creation
    return getAuthError(error);
}
